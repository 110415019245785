const CameraSelectionContainer = () => {
  return (
    <div className="pt-4 relative group cursor-pointer">
      <img src={cameraSelection} alt="" />
      <div
        className="opacity-0 group-hover:opacity-100
                   rounded-lg bg-red-400
                   text-white text-center text-xs
                   ml-16 w-36 py-2 z-10
                   absolute -bottom-10"
      >
        Future functionality!
        <div className="absolute bg-red-400 h-3 w-3 -top-1.5 left-16 rotate-45"></div>
      </div>
    </div>
  )
}

export default CameraSelectionContainer

const cameraSelection = `${process.env.PUBLIC_URL}/assets/camera_selection.png`
